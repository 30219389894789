import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { FormField, validFormField } from "../Form/FormField";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import "./ContactForm.scss";
import { useSubmitContactSupportMutation } from "../../service/api/contactSupportApi";
import { useGetOperatorDetailQuery } from "../../service/api/meveoApi";
import LoadingModal from "../LoadingModal";

const ContactForm = (props) => {
	const initData = {
		first_name: {
			id: "first_name",
			label: t("common:first_name"),
			errors: [],
			valid: [
				[".{2,}", "Required and at least 2 characters"]
			],
			value: "",
		},
		last_name: {
			id: "last_name",
			label: t("common:last_name"),
			errors: [],
			valid: [
				[".{2,}", "Required and at least 2 characters"]
			],
			value: "",
		},
		email: {
			id: "email",
			label: t("common:email"),
			errors: [],
			valid: [
				[".{8,}", "Required and must be an email"]
			],
			value: "",
		},
		reason: {
			id: "reason",
			label: t("pages:help.reason_field_label"),
			errors: [],
			valid: [
				[".{3,}", "Required"]
			],
			value: "",
			type: "select",
			options: t("pages:help.contact.reasons", { returnObjects: true })
		},
		message: {
			id: "message",
			label: t("common:message"),
			type: "multiline",
			errors: [],
			valid: [
				[".{5,}", "Required and at least 5 characters"]
			],
			value: "",
			fullWidth: true,
		},
	};
	const [data, setFormData] = useState(initData);
	const [reloadKey, setReloadKey] = useState(new Date().getTime());

	const [submitSupport, { isLoading: submitting }] = useSubmitContactSupportMutation();
	const { data: operatorInfo } = useGetOperatorDetailQuery();
	const operatorCode = useMemo(() => operatorInfo?.result?.partnerCode || '', [operatorInfo]);

	const handleChange = (event, value, id) => {
		let fieldId = !!event ? event?.target?.name || id : id;
		let fieldValue = !!event ? event?.target?.value : value;
		if (!fieldId) return;

		let updateData = data[fieldId];
		if (!updateData) return;

		updateData.value = fieldValue;

		setFormData({
			...data,
			[fieldId]: updateData,
		});
	};

	const onSubmit = async (evt) => {
		evt.preventDefault();
		let isValid = true;
		for (let id in data) {
			let fieldErrors = validFormField(id, data[id].value, data);
			data[id].errors = fieldErrors;
			isValid = isValid && fieldErrors.length <= 0;
		}
		setFormData({ ...data });
		if (!isValid) return;

		let payload = {
			operator_code: operatorCode
		};
		Object.values(data).forEach((field) => {
			payload[field["id"]] = field["value"];
		});
		submitSupport(payload).unwrap().then((response) => {
			if (response.success || response.succes) {
				toast.success(t("pages:help.success_message"));
				// clear input fields
				for (let id in data) {
					data[id].value = '';
				}
				setFormData({ ...data });
				setReloadKey(new Date().getTime());
			}
		}).catch((error) => {
			toast.error(t("pages:help.error_message", { error: error.error || error.message }));
		})
	}

	return (
		<Box component="form" className="flex contact-form " onSubmit={onSubmit}>
			<Box className="w--50" key={reloadKey}>
				{Object.values(data).map((field) => (
					<FormField
						fieldId={field["id"]}
						key={`contact-form-field-${field["id"]}`}
						value={field["value"]}
						onChange={handleChange}
						label={field.label}
						errors={field.errors}
						className={`contact-form__field contact-form__field-${field["id"]} ${!field.fullWidth ? "w--50" : ""
							}`}
						fullWidth={field.fullWidth || false}
						type={field.type || "default"}
						options={field.options || false}
					/>
				))}

				<Button type="submit" disabled={submitting} variant="contained" color="secondary">
					{t("common:send")}
				</Button>
				{submitting && <LoadingModal visible={submitting} />}
			</Box>
		</Box>
	);
};

export default ContactForm;
